import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './ChartApp.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartApp = () => {
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [pastHours, setPastHours] = useState(0)
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [currentChartData, setCurrentChartData] = useState({ labels: [], datasets: [] });
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);
  const [dateRange, setDateRange] = useState({
    start_date: null,
    end_date: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  function fillMissingRecords(data) {
    // Find the oldest and newest dates across all parameters
    let oldestDate, newestDate;

    for (const parameter in data) {
        if (data.hasOwnProperty(parameter)) {
            data[parameter].forEach(entry => {
                if (!oldestDate || entry.start_date < oldestDate) {
                    oldestDate = entry.start_date;
                }

                if (!newestDate || entry.start_date > newestDate) {
                    newestDate = entry.start_date;
                }
            });
        }
    }

    // Fill in missing records for each parameter
    const filledData = {};

    for (const parameter in data) {
        if (data.hasOwnProperty(parameter)) {
            filledData[parameter] = [];

            for (let currentDate = new Date(oldestDate); currentDate <= new Date(newestDate); currentDate.setHours(currentDate.getHours() + 1)) {
                const existingEntry = data[parameter].find(entry => entry.start_date === currentDate.toISOString());

                if (existingEntry) {
                    // Keep the existing record
                    filledData[parameter].push({
                        start_date: existingEntry.start_date,
                        end_date: existingEntry.end_date,
                        values: existingEntry.values || Array(12).fill(null)
                    });
                } else {
                    // Insert a new record with end_date set to 12 hours later than start_date and historical null values
                    const startDateISO = currentDate.toISOString();
                    const endDate = new Date(currentDate);
                    endDate.setHours(endDate.getHours() + 12);
                    const endDateISO = endDate.toISOString();
                    filledData[parameter].push({
                        start_date: startDateISO,
                        end_date: endDateISO,
                        values: Array(12).fill(null)
                    });
                }
            }
            filledData[parameter].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        }
    }
    return filledData;
}

  function relocateParameterData(currentChartData, dateIndex) {
    const array_data = [currentChartData]
    const current_selected_data = array_data.map(parameter => {
      const parameterData = {};

      for (const key in parameter) {
        if (parameter.hasOwnProperty(key) && parameter[key].length > 0) {
          parameterData[key] = parameter[key][Math.abs(dateIndex)];
        }
      }

      return parameterData;
    });

    return current_selected_data;
  }

  function prepareChartData(current_selected_data, currentChartData,  isPortrait) {
    const newChartData = {
      labels: generateLabels(current_selected_data[0].icon_d2.start_date, current_selected_data[0].icon_d2.end_date, isPortrait),
      datasets: Object.keys(currentChartData).map((paramKey, index) => ({
        label: paramKey,
        data: current_selected_data[0][paramKey].values,
        borderColor: getRandomColor(paramKey),
        backgroundColor: getTransparentColor(index),
        borderWidth: getBorderWidth(paramKey),
        hidden: paramKey == 'icon_d2' ? true : false
      })),
    };
    return newChartData;
  }

  const changeSliderData = async (dateIndex) => {
    const current_selected_data = relocateParameterData(currentChartData, dateIndex);
    const newChartData = prepareChartData(current_selected_data, currentChartData, false);
    setChartData(newChartData);
  }

  const handleDateRangeChange = (dates) => {
    setDateRange({
      start_date: dates[0],
      end_date: dates[1],
    });

    // Calculate pastDays from dates[0] and dates[1]
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const startDateMilliseconds = dates[0];
    const endDateMilliseconds = dates[1];
    const pastDays = Math.floor((endDateMilliseconds - startDateMilliseconds) / millisecondsPerDay);

    fetchData(0, pastDays, dates[0], dates[1])
    // Call your changeSliderData function here with the selected date range
    //changeSliderDataWithDateRange(dates[0], dates[1]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // Call your changeSliderData function here with the selected date range
    if (dateRange.start_date && dateRange.end_date) {
      //changeSliderDataWithDateRange(dateRange.start_date, dateRange.end_date);
    } else {
      //changeSliderData(selectedDays);
    }
  };


  const fetchData = async (dateIndex, pastDays, start_date=null, end_date=null) => {
    try {
      const token = process.env.REACT_APP_API_KEY;
      let response = null;
      if (!start_date && !end_date) {
        response = await fetch(`${process.env.REACT_APP_BASE_URL}/history?past_days=${pastDays}`, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        });
      }
      else {
        response = await fetch(`${process.env.REACT_APP_BASE_URL}/history?start_date=${start_date.toISOString()}&end_date=${end_date.toISOString()}`, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        });
      }


      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let data = await response.json(); 
      delete data.t_12h_3_1_5;
      data = fillMissingRecords(data);
      const current_selected_data = relocateParameterData(data, dateIndex)

      setPastHours(data['icon_d2'].length-2)
      setCurrentChartData(data)

      const newChartData = prepareChartData(current_selected_data, data, isPortrait)

      setChartData(newChartData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const generateLabels = (start_date, end_date, isMobile) => {
    const start = new Date(start_date);
    const end = new Date(end_date);
    const labels = [];
  
    for (let currentDate = new Date(start); currentDate <= end; currentDate.setHours(currentDate.getHours() + 1)) {
      // Convert UTC to Europe/Ljubljana time zone
      const formattedDate = isMobile
        ? currentDate.toLocaleString('sl-SI', { timeZone: 'Europe/Ljubljana', hour: '2-digit', minute: '2-digit' })
        : currentDate.toLocaleString('sl-SI', {
            timeZone: 'Europe/Ljubljana',
            day: '2-digit',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
  
      labels.push(formattedDate);
    }
  
    return labels;
  };
  

  const handleChangeDateIndex = (dateIndex) => {
    setCurrentDateIndex(dateIndex);
    changeSliderData(dateIndex);
  };

  const handleButtonClick = (pastDays) => {
    const pastHours = pastDays * 24;
    setPastHours(pastHours)
    const currentDateIndex = -pastHours; // Assuming you want to start from the latest data
    setCurrentDateIndex(0);
    fetchData(0, pastDays);
  };

  useEffect(() => {
    // Fetch data when the component initially loads
    fetchData(currentDateIndex, 3);

    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []); // Empty dependency array ensures it runs only once on mount

  const options = {
    responsive: true,
    maintainAspectRatio : true,
    aspectRation: 2,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            weight: 'bold', // Make the legend items (labels) bold
            size: isPortrait ? 12 : 14, // Adjust the font size based on orientation
          },
        },
      },
      title: {
        display: true,
        text: 'Temperature Prediction For Next 12 Hours',
        font: {
          weight: 'bold', // Make the legend items (labels) bold
          size: isPortrait ? 13 : 20, // Adjust the font size based on orientation
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        type: 'category',
        labels: chartData.labels,
        ticks: {
          font: {
            weight: 'bold',
            size: isPortrait ? undefined : 14,
          }
        }
      },
      y: {
        type: 'linear',
      },
    },
  };

  const buttonStyles = {
    padding: '15px', // Adjust padding as needed
    fontSize: '16px', // Adjust font size as needed
    marginRight: '10px',
    marginBottom: '10px'
  };

  const sliderStyles = {
    width: '95%', // Adjust the width as needed
    margin: '20px auto', // Adjust margin as needed
  };
  
  return (
    <div style={{ position: "relative", margin: "auto", width: isPortrait ? "90vw" : "80vw"}}>
      {isPortrait ? <h2>Maximus Forecasting</h2> : <h1>Maximus Forecasting</h1>}
      {/* Rest of your component */}
      <Line options={options} data={{ labels: chartData.labels, datasets: chartData.datasets }} height={isPortrait ? 550 : undefined} />
      <Slider 
      min={-(pastHours)} 
      max={0} 
      value={currentDateIndex} 
      onChange={handleChangeDateIndex} 
      style={sliderStyles}
      trackStyle={{ backgroundColor: "gray", height: 10 }}
      railStyle={{ backgroundColor: "lightblue", height: 10 }}
      handleStyle={{
        borderColor: "black",
        height: 30,
        width: 30,
        marginLeft: -10,
        marginTop: -10,
        backgroundColor: "black"
      }}>
      </Slider>

      <button onClick={() => handleButtonClick(3)} style={buttonStyles}>
        Last 3 Days
      </button>
      <button onClick={() => handleButtonClick(7)} style={buttonStyles}>
        Last 7 Days
      </button>
      <button onClick={() => handleButtonClick(14)} style={buttonStyles}>
        Last 14 Days
      </button>
      <input
        type="text"
        onClick={() => setIsModalOpen(true)}
        placeholder="Select Date Range"
        readOnly
        style={{ cursor: 'pointer' }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            width: '400px',
            height: '400px',
            borderRadius: '8px',
            overflow: 'hidden',
          },
        }}
      >
        <DatePicker
          selected={dateRange.start_date}
          onChange={(dates) => handleDateRangeChange(dates)}
          startDate={dateRange.start_date}
          endDate={dateRange.end_date}
          selectsRange
          inline
        />
        <button onClick={closeModal}>Apply</button>
      </Modal>
    </div>
  );
};

const getRandomColor = (paramKey) => {
  const colorMap = {
    'benedikt_t_2m': 'red',
    'icon_d2': 'brown',
    't_12h_1_1_10': 'green',
  };

  // Check if the parameter is in the map, use a default color otherwise
  return colorMap[paramKey] || 'purple';
};

// Helper function to generate a transparent version of a color
const getTransparentColor = (index) => {
  const color = getRandomColor(index);
  return `rgba(${hexToRgb(color)}, 0.5)`;
};

// Helper function to convert hex color to RGB format
const hexToRgb = (hex) => {
  // ... (implement your logic to convert hex to RGB, you can find many online examples)
  return '255, 255, 255'; // Replace with your actual implementation
};

// Helper function to set borderWidth based on the parameter key
const getBorderWidth = (paramKey) => {
  // Set different borderWidth for a specific parameter (e.g., 'ParameterToMakeBigger')
  return paramKey === 'benedikt_t_2m' ? 7 : 2;
};

export default ChartApp;
