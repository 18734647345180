import logo from './logo.png';
import './App.css';
import ChartApp from './components/ChartApp';

function App() {
  return (
    <div className="App">
      <ChartApp />
    </div>
  );
}

export default App;
